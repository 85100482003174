@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  background-color: #282c34;
}

.Root-Background {
  background-color: #282c34;
}

.clickable {
  cursor: pointer;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Main-Header {
  background-color: #282c34;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modal1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 200;
}

.modal-main {
  position: fixed;
  animation: slideDown 0.5s ease forwards;
  background: black;
  width: 80%;
  height: auto;
  left: 10%;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

@keyframes slideDown {
  0% {
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(100px);
    opacity: 1;
  }
}

.btn-navlink {
  background-color: transparent;
  color: white;
  padding-top: 8px;
  border: none;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.form-error-label {
  color: red;
}

.dashboard-button-bg-lightgrey {
  background-color: #c3b4b4dc;
}
.dashboard-button-bg-lightblue {
  background-color: #518bf6;
}

table.contractdetail {
  width: 300px;
  border-collapse: collapse;
  border: 1px solid white;
  margin-right: 20px;
  margin-left: auto;
  background-color: #212529;
}

.contractdetaildata {
  padding: 15px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

.navbar {
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
  z-index: 100;
}

.menu-bars {
  margin-left: 1rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: #060b26;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 9px 8px 0px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
  color: #f5f5f5;
}

.nav-menu-items {
  width: 100%;
  padding-left: 0;
}

.navbar-toggle {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  margin-left: 16px;
}

